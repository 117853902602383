export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/erro'
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/admin/erro/Erro.vue'),
    meta: {
      title: 'Erro | UnicPages',
      layout: 'default-layout'
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../components/admin/usuarios/Usuarios.vue'),
    meta: {
      logged: true,
      title: 'Usuários | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/sites',
    name: 'sites',
    component: () => import('../components/admin/sites/Sites.vue'),
    meta: {
      logged: true,
      title: 'Sites na Unic | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/dados',
    name: 'dados',
    component: () => import('../components/admin/dados/Dados.vue'),
    meta: {
      logged: true,
      title: 'Dados da Unic | UnicPages',
      layout: 'navbar-layout'
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('../components/admin/templates/Templates.vue'),
    meta: {
      logged: true,
      title: 'Templates da Unic | UnicPages',
      layout: 'navbar-layout'
    }
  }
]
